'use client';

import { useState, useEffect, ChangeEvent } from 'react';
import { Season, Show } from '@/lib/types/api/show-data';
import VideoDetailThumbnail from '@/components/VideoDetailThumbnail/VideoDetailThumbnail';
import styles from './SeasonNavigator.module.scss';
import LoadingIndicator from '@/components/LoadingIndicator/LoadingIndicator';
import { VideoClass, VideoShow } from '@/lib/types/api/video';
import { canAccessStorage } from '@/lib/helpers/is-storage-available';
async function fetchEpisodesFor(showSlug: string, cid: string) {
  const url = `/api/show/${showSlug}/season/${cid}/episodes/`;
  const response = await fetch(url);
  return await response.json();
}
async function fetchExtrasFor(showSlug: string, cid: string) {
  const url = `/api/show/${showSlug}/season/${cid}/extras/`;
  const response = await fetch(url);
  return await response.json();
}
export interface SeasonNavigatorProps {
  showSlug: string;
  videoSlug?: string;
  seasons: Season[];
  show?: Show | VideoShow;
  videos: VideoClass[];
  defaultSeason?: string;
  contentType?: 'episodes' | 'extras';
}
function SeasonNavigator(props: SeasonNavigatorProps) {
  const {
    show,
    showSlug,
    videoSlug,
    contentType = 'episodes'
  } = props;
  const seasons = props.seasons.filter(s => s.cid !== 'all-seasons');
  let {
    defaultSeason
  } = props;
  if (!defaultSeason) {
    defaultSeason = seasons[0].cid;
  }
  const [selectedSeason, setSelectedSeason] = useState(defaultSeason);
  const [videos, setVideos] = useState<VideoClass[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const cid = event.target.value;
    setSelectedSeason(cid);
  };
  useEffect(() => {
    setIsLoading(true);

    // RWEB-8958 - don't ask for episodes if the season is 'more-extras' - that's not a thing
    if (contentType === 'episodes' && selectedSeason !== 'more-extras') {
      fetchEpisodesFor(showSlug, selectedSeason).then(json => {
        setVideos(json);
        setIsLoading(false);
      });
    } else if (contentType === 'extras') {
      fetchExtrasFor(showSlug, selectedSeason).then(json => {
        setVideos(json);
        setIsLoading(false);
      });
    }
  }, [selectedSeason, showSlug, contentType]);
  useEffect(() => {
    if (videos.length === 0 && !canAccessStorage('localStorage')) {
      setErrorMessage("No results. It's possible your browser is blocking cookies or in an unsupported country.");
    } else {
      setErrorMessage(null);
    }
  }, [videos]);
  return <div className={styles.season_navigator} data-sentry-component="SeasonNavigator" data-sentry-source-file="SeasonNavigator.tsx">
      {seasons.length > 1 && <select className={styles.season_navigator_select} name='season-picker' defaultValue={defaultSeason} onChange={handleSelect}>
          {seasons.map((season, index: number) => {
        if (season.ordinal === 0) {
          return <option value={season.cid} key={index}>More Clips & Previews</option>;
        }
        return <option value={season.cid} key={index}>Season {season.ordinal}</option>;
      })}
        </select>}

      <div className={styles.season_navigator__episodes}>
        {isLoading && <LoadingIndicator className={styles.season_navigator__loading} />}

        {!isLoading && errorMessage && <p>{errorMessage}</p>}

        {!isLoading && videos.map((video, index: number) => {
        const videoEpisode = video.parent?.ordinal;
        const videoSeason = video.parent?.season?.ordinal;
        video.show = ({
          ...show,
          episode: videoEpisode,
          season: videoSeason,
          display_episode_number: videoEpisode
        } as VideoShow);
        video.parent_type = 'show';
        return <VideoDetailThumbnail key={index} video={video} nowPlaying={video.slug === videoSlug} />;
      })}
      </div>
    </div>;
}
export default SeasonNavigator;