'use client';

// imports
import Link from "next/link";
import { useState, useEffect } from 'react';

// lib files
import { ShowDetails, FeaturedPreview } from "@/lib/types/api/show-data";
import { DefaultSeasonEpisodesContent, VideoTypeEnum } from "@/lib/types/api/video";
import { INTERNALLY_NAVIGATING_KEY } from '@/lib/constants';
import { StationData } from '@/lib/types/api/stations-data';

// components
import BackButton from "@/components/Button/BackButton";
import ComponentTransition from '@/components/Transitions/ComponentTransition';
import FromFranchise from '@/components/FromFranchise/FromFranchise';
import ITSImage from "@/components/ITSImage/ITSImage";
import KabobMenu from "@/components/KabobMenu/KabobMenu";
import LinkButton from "@/components/Button/LinkButton";
import MezzanineVideoInitializer from '@/components/MezzanineVideoInitializer/MezzanineVideoInitializer';
import MyListButton from '@/components/Button/MyList/MyListButton';
import SponsorshipRow from '@/components/SponsorshipRow/SponsorshipRow';
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer";

// svgs
import ExternalLinkIcon from '/public/svg/external-link.svg';

// styles
import styles from './ShowDetailHero.module.scss';
import { canAccessStorage } from "@/lib/helpers/is-storage-available";
interface ShowDetailHeroProps {
  episodeToDirectTo?: DefaultSeasonEpisodesContent;
  featuredPreview?: FeaturedPreview;
  isSVP: boolean;
  show: ShowDetails;
  sponsorInfoLink?: string;
  stationData?: StationData;
}
const ShowDetailHero: React.FC<ShowDetailHeroProps> = props => {
  const {
    episodeToDirectTo,
    featuredPreview,
    isSVP,
    show,
    sponsorInfoLink,
    stationData
  } = props;
  const descriptionToDisplay = show.description_long || show.description_short || null;
  const [showPreviewFromButton, setShowPreviewFromButton] = useState(false);
  const [isInternallyNavigating, setIsInternallyNavigating] = useState(false);
  useEffect(() => {
    if (canAccessStorage('sessionStorage')) {
      setIsInternallyNavigating(sessionStorage.getItem(INTERNALLY_NAVIGATING_KEY) === 'true');
    }
  }, []);

  // @TODO at whatever point we add a user setting for auto play & sound, we will need to account for that here
  const hasAvailableFeaturedPreview = featuredPreview && featuredPreview.availability === 'available';
  const letsShowThePreview = hasAvailableFeaturedPreview && (isInternallyNavigating || showPreviewFromButton);
  const previewShouldBeMuted = !showPreviewFromButton;
  let showBackgroundImage: string | null = null;

  // For background art, prioritize the show's "background"
  if (show.images?.['background']) {
    showBackgroundImage = show.images['background'];
  } else if (episodeToDirectTo?.image) {
    // fall back to the episode that we're sending users to's image
    // looking at you, NOVA
    showBackgroundImage = episodeToDirectTo.image;
  }

  // we shift the preview layout controls up if there is a sponsorship row to render
  const shouldShiftControlsUp = show.funder_message != '' || show.sponsor_logos && show.sponsor_logos?.length > 0;
  let moveControlsUpBreakpoint = undefined;
  if (shouldShiftControlsUp) {
    moveControlsUpBreakpoint = 1024;
  }
  const ShowPreviewOrBackgroundImage = () => {
    if (letsShowThePreview) {
      const playerConfig = {
        autoplay: true,
        muted: previewShouldBeMuted,
        disableContinuousPlay: true,
        embedType: isSVP ? 'stationplayer' : 'portalplayer',
        previewLayout: true,
        shiftControlsUp: shouldShiftControlsUp,
        stationId: stationData?.id
      };
      return <VideoPlayer video={featuredPreview} playerConfig={playerConfig} moveControlsUpBreakpoint={moveControlsUpBreakpoint} />;
    } else if (hasAvailableFeaturedPreview) {
      return <MezzanineVideoInitializer videoType={(featuredPreview.videoType.toLowerCase() as VideoTypeEnum)} imgSrc={featuredPreview.mezzanine16x9ImageUrl} alt={featuredPreview.title} verticalOffsetButton={false} onClick={() => setShowPreviewFromButton(true)} width={860} className={styles.detail_hero_mezzanine_video_initializer} />;
    } else if (showBackgroundImage) {
      return <div className={styles.detail_hero_show_image}>
          <ITSImage src={showBackgroundImage} alt={show.title} width={860} height={480} loading="eager" />
        </div>;
    }
  };
  let showPreviewBackgroundClassName = styles.detail_hero_background_image;
  if (letsShowThePreview) {
    showPreviewBackgroundClassName = styles.detail_hero_video;
  }
  let logoToShow = null;
  if (show.images?.['white-logo-41']) {
    logoToShow = show.images['white-logo-41'];
  } else if (show.images?.['show-white-logo']) {
    logoToShow = show.images['show-white-logo'];
  }
  return <div className={styles.detail_hero} data-sentry-component="ShowDetailHero" data-sentry-source-file="ShowDetailHero.tsx">
      <BackButton className={styles.detail_hero_back_button} data-sentry-element="BackButton" data-sentry-source-file="ShowDetailHero.tsx" />

      <div className={showPreviewBackgroundClassName}>
        <ComponentTransition data-sentry-element="ComponentTransition" data-sentry-source-file="ShowDetailHero.tsx">
          <ShowPreviewOrBackgroundImage data-sentry-element="ShowPreviewOrBackgroundImage" data-sentry-source-file="ShowDetailHero.tsx" />
        </ComponentTransition>
      </div>

      <div className={styles.detail_hero_overlay}>
        <h1 className={styles.detail_hero_show_name}>
          {logoToShow ? <ITSImage src={logoToShow} alt={show.title} width={300} /> : <>{show.title}</>}
        </h1>

        {show.broadcast_info && <p className={styles.detail_hero_broadcast_info}>{show.broadcast_info}</p>}

        {descriptionToDisplay && <p className={styles.detail_hero_description}>{descriptionToDisplay}</p>}

        {show.franchise && <FromFranchise franchise={show.franchise} className={styles.show_franchise_label} />}

        <div className={styles.detail_hero_overlay_buttons}>
          {episodeToDirectTo && <LinkButton style='blue' iconBefore="play" href={`/video/${episodeToDirectTo.slug}/`}>
              Watch Now
            </LinkButton>}

          {show.website && <LinkButton href={show.website} target="_blank" className={styles.detail_hero_overlay_buttons_website_button}>
              Visit Official Site
            </LinkButton>}

          <MyListButton show={show} style='iconOnly' data-sentry-element="MyListButton" data-sentry-source-file="ShowDetailHero.tsx" />

          {show.website && <KabobMenu className={styles.detail_hero_overlay_buttons_kabob_menu}>
              <Link href={show.website} className={styles.detail_hero_overlay_buttons_kabob_menu_external_link_icon}>
                <ExternalLinkIcon width="16" height="13" />
                Visit The Official Site
              </Link>
            </KabobMenu>}
        </div>
      </div>

      <SponsorshipRow source={show} title={show.title} sponsorInfoLink={sponsorInfoLink} className={styles.sponsorship_row__top} sponsorshipLogosPagePosition='top' sponsorshipTextVisibility='desktop' data-sentry-element="SponsorshipRow" data-sentry-source-file="ShowDetailHero.tsx" />
    </div>;
};
export default ShowDetailHero;